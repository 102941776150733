.style-card-wrapper {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: calc(5px + 0.5vw);
  cursor: pointer;
  color: #4d4d4d;
}

.style-card-wrapper:hover {
  border: 1px solid #242627;
  color: #242627;
}

.style-card-style-image {
  min-height: calc(200px + 10vw);
  min-width: calc(300px + 10vw);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  position: relative;
}

.style-card-style-image:hover .style-card-style-name {
  display: inherit;
}

.style-card-style-name {
  padding: calc(5px + 0.5vw);
  display: none;
  background-color: rgb(31, 31, 31, 1);
  border-radius: 5px;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
}

.style-card-style-name h5 {
  font-size: calc(10px + 0.5vw);
  margin: 0;
}
