.view-box-wrapper {
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
  border: 1px solid #f1f1f1;
}

.view-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px calc(5px + 0.5vw);
  min-width: 300px;
  background-color: #242627;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
}

.view-box-header h5 {
  margin: 0 0 0 calc(10px + 0.5vw);
  font-size: calc(8px + 0.5vw);
}

.view-box-header-action-buttons-row {
  display: flex;
  align-items: center;
}

.view-box-header-action-button {
  width: calc(6px + 0.5vw);
  height: calc(6px + 0.5vw);
  cursor: pointer;
}

.view-box-header-action-button:hover {
  color: #ff4e24;
  border: 1px solid #ff4e24;
}

.view-box-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 250px;
}
