.addRoomMenu {
  position: absolute;
  z-index: 1000;
  width: 150px;
  margin: 0;
  border: 1px solid #bbb;
  background: #ffffff;
  border-radius: 3px;
  padding: 15px;
  transform: translate(0, 15px) scale(0.95);
  pointer-events: none;
}

.addRoomMenu-item {
  width: 100%;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.addRoomMenu-item:hover {
  color: #ff4b31;
}

.addRoomMenu-btn {
  padding-left: 0;
  background: #ffffff;
  display: block;
  font-size: 16px;
  cursor: pointer;

  white-space: nowrap;
  padding: 6px 8px;
  border-radius: 3px;
}

.addRoomMenu-text {
  margin-left: 0px;
}
