.design-elements-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.design-elements-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: flex-end;
}

.design-elements-display-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding: 2px 20px;
  position: relative;
  background-color: #242627;
  color: #ffffff;
}

.design-elements-display-header h1 {
  margin: 0;
  font-size: calc(8px + 0.5vw);
  text-transform: uppercase;
}

.design-elements-display-categories {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: calc(2px + 0.2vw) calc(10px + 1vw);
}

.design-elements-display-category {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: calc(2px + 0.2vw) calc(10px + 1vw);
  margin-left: calc(5px + 0.5vw);
  cursor: pointer;
  font-size: calc(5px + 0.5vw);
}

.design-elements-display-category:hover {
  background-color: #242627;
  color: #ffffff;
}
.design-elements-add:hover {
  background-color: #242627;
  color: #ffffff;
}

.design-elements-display-body {
  padding: calc(5px + 0.5vw);
  display: flex;
  align-items: center;
  width: 100%;
  overflow: scroll;
  min-height: 200px;
  position: relative;
}

.design-elements-add {
  background-color: #ff4b31;
  border-radius: 5px;
  padding: calc(2px + 0.2vw) calc(10px + 1vw);
  margin-left: calc(5px + 0.5vw);
  cursor: pointer;
  font-size: calc(5px + 0.5vw);
}
