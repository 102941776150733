.rooms-modal-wrapper {
  width: calc(300px + 40vw);
}

.rooms-modal-wrapper h4 {
  font-size: calc(12px + 1vw);
  font-weight: lighter;
  margin: 0 0 0 calc(12px + 1vw);
}

.rooms-modal-rooms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.rooms-modal-room-container {
  padding: 20px;
}
