.design-menus-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #242627;
  box-shadow: 0px 0px 5px #e5e9f2;
  padding: calc(20px + 1vw) calc(5px + 0.5vw) 0 calc(5px + 0.5vw);
}

.design-menus-menu-icon {
  width: calc(15px + 1vw);
  height: calc(15px + 1vw);
  color: #b8c5d3;
  margin-bottom: calc(20px + 1vw);
  cursor: pointer;
}

.design-menus-menu-icon:hover {
  color: #ff4e24;
}

.design-menus-menu-container {
  position: relative;
}

.design-menus-menu-tool-tip {
  position: absolute;
  top: 0;
  left: calc(15px + 1vw);
  display: none;
}

.design-menus-menu-tool-tip-caret {
  color: rgb(229, 233, 242, 0.5);
  width: calc(10px + 0.5vw);
  height: calc(10px + 0.5vw);
}

.design-menus-menu-description {
  background-color: rgb(229, 233, 242, 0.5);
  width: calc(80px + 8vw);
  padding: calc(2px + 0.2vw);
  color: #ff4e24;
  border-radius: 5px;
}

.design-menus-menu-description h6 {
  margin: 0;
  font-size: calc(3px + 0.3vw);
}

.design-menus-menu-description p {
  margin: 0;
  font-size: calc(3px + 0.3vw);
  color: #242627;
}

.design-menus-menu-container:hover .design-menus-menu-tool-tip {
  display: flex;
}
