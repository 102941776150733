.items-list-item-card {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: calc(5px + 0.5vw);
  height: 100%;
  color: #4d4d4d;
  background: #ffffff;
}

.items-list-item-card:hover {
  border: 1px solid #ff4e24;
  color: #ff4e24;
}

.items-list-item-card:hover .items-list-item-details h6 {
  font-weight: bold;
}

.items-list-item-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
}

.items-list-item-details h6 {
  margin: 10px;
  font-size: calc(5px + 0.5vw);
  text-transform: capitalize;
  text-align: center;
  font-weight: lighter;
  width: 100%;
}

.items-list-item-counter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.items-list-item-counter h4 {
  margin: 0;
  font-size: calc(10px + 0.5vw);
  text-align: right;
  width: 100%;
  color: #242627;
}
