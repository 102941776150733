.color-filter-wrapper {
  padding: calc(5px + 0.5vw);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.color-filter-label {
  color: #4d4d4d;
  margin: 0 0 calc(10px + 0.5vw) 10px;
}

.color-filter-color-picker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
