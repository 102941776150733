.styles-view-box-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center safe;
  align-items: center;
  overflow: scroll;
  padding: calc(5px + 0.5vw) calc(20px + 2vw) calc(5px + 0.5vw)
    calc(5px + 0.5vw);
}

.styles-view-box-selected-style {
  min-width: 100%;
  min-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #f2f2f2;
  position: relative;
  margin-right: calc(5px + 0.5vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b8bfce;
}

.styles-view-box-selected-style:hover {
  color: #ff4e24;
}

.styles-view-box-selected-style:hover .styles-view-box-selected-style-details {
  display: flex;
}

.styles-view-box-selected-style-details {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  overflow: scroll;
}

.styles-view-box-selected-style-details div {
  background-color: rgb(31, 31, 31, 0.8);
  padding: calc(10px + 0.5vw);
  border-radius: 5px;
}

.styles-view-box-selected-style-details h1 {
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: calc(18px + 1vw);
}

.styles-view-box-add-style-icon {
  width: calc(50px + 5vw);
  height: calc(50px + 5vw);
  cursor: pointer;
}

.styles-view-box-selected-style h4 {
  font-size: calc(10px + 1vw);
  margin: calc(5px + 0.5vw) 0 0 0;
}
