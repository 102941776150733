.items-list-wrapper {
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 5px #e5e9f2; */
  max-width: calc(300px + 12vw);
  height: 100%;
  width: calc(200px + 2vw);
}

.items-list-wrapper-fixed {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
}

.items-list-item-card-container {
  width: calc(200px + 2vw);
  padding: calc(3px + 0.3vw);
  height: 150px;
  cursor: pointer;
  /* background: #ffffff; */
}

.items-list-item-icon {
  width: calc(50px + 2vw);
  height: calc(50px + 2vw);
  color: #b8c5d3;
}
