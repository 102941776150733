.miniCanvas-canvas {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}
.miniCanvas-item {
  position: absolute;
  cursor: pointer;
}
