.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #242627;
  color: #ffffff;
  padding: 3px;
  font-size: calc(6px + 0.3vw);
  z-index: 1;
}

.footer-wrapper p {
  margin: 0;
}
