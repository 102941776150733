.editModal-container {
  width: 50vw;
  height: 70vh;
  padding: 15px 0 15px 0;
  border-top: 1px solid black;
}

.editModal-wrapper {
  height: 90%;
  border-bottom: 1px solid black;
  padding: 10px 0 10px 0;
}

.editModal-buttons {
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
}

.editModal-fields {
  padding-top: 10px;
}
