.weight-filter-wrapper {
  padding: calc(10px + 1vw);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.weight-filter-label {
  color: #4d4d4d;
  margin: 0 0 calc(5px + 0.5vw) 10px;
}

.weight-filter-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
