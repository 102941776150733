.room-card-wrapper {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  width: 300px;
  height: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  color: #4d4d4d;
}

.room-card-wrapper:hover {
  border: 1px solid #242627;
  color: #242627;
}

.room-card-icon-and-title-container h5 {
  font-size: 14px;
  margin-top: 0;
}

.room-card-progressBar-container {
  display: flex;
  position: relative;
  margin-top: 135px;
}

.room-card-status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.room-card-status-icon {
  width: 10px;
  height: 10px;
}

.room-card-miniCanvas-container {
  position: absolute;
  background-color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
