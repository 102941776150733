.filters-wrapper {
  height: 100%;
  /* overflow: scroll; */
  padding: 5px 0 calc(20px + 1vw) 0;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.airtable-filters-wrapper {
  height: 100%;
  /* overflow: scroll; */
  padding: 5px 0 calc(20px + 1vw) 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.filters-header {
  z-index: 1;
  background-color: #ffffff;
  padding: 0 calc(10px + 1vw) calc(5px + 0.5vw) calc(10px + 1vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filters-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 calc(10px + 1vw) 0;
}

.filters-header-top h4 {
  font-size: calc(8px + 0.5vw);
  margin: 0;
}

.filters-close-icon {
  width: 20px;
  height: 20px;
  color: #ff4e24;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.filters-close-icon:hover {
  color: #0070da;
}

/* 
.filters-header h4 {
  font-size: calc(8px + 0.5vw);
  text-align: left;
  width: 100%;
  padding: 0 0 calc(10px + 1vw) calc(20px + 1vw);
  margin: 0;
} */

.filters-body {
  flex: 1;
  overflow: scroll;
  padding-bottom: calc(50px + 2vw);
  display: flex;
  flex-direction: column;
}

.filters-search-button {
  position: fixed;
  width: 25%;
  bottom: 0;
  /* left: 0;
  right: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(2px + 0.2vw) calc(10px + 1vw) calc(10px + 1vw) calc(10px + 1vw);
  background-color: #ffffff;
  border-top: 1px solid #f2f2f2;
}

.filters-loading-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.9);
  z-index: 2;
  left: calc(5px + 0.5vw);
  right: 0;
  bottom: 0;
}