.product-card-wrapper {
  padding: calc(5px + 0.5vw) calc(5px + 0.5vw) 0 0;
  position: relative;
}

.product-card-wrapper-container {
  box-shadow: 0px 0px 5px #e5e9f2;
  /* cursor: pointer; */
  background-color: #ffffff;
  border-radius: 5px;
}

.product-card-product-image {
  min-height: 200px;
  min-width: 198px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.product-card-wrapper-container-overlay {
  background-color: rgb(36, 38, 39, 0.8);
  position: absolute;
  top: 0;
  min-height: 200px;
  min-width: 198px;
  max-height: 200px;
  max-width: 198px;
  display: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: calc(5px + 0.5vw);
  overflow: scroll;
  color: #ffffff;
  z-index: 1;
}

.product-card-wrapper-container-overlay-top h5 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  width: 100%;
  text-align: center;
}

.product-card-wrapper-container-overlay-top h2 {
  margin: calc(2px + 0.2vw) 0;
  font-size: calc(10px + 0.5vw);
  width: 100%;
  text-align: center;
}

.product-card-wrapper-container-overlay-top p {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  width: 100%;
  text-align: center;
}

.product-card-wrapper-container-overlay-bottom {
  width: 100%;
}

.product-card-wrapper-container:hover .product-card-wrapper-container-overlay {
  display: flex;
}

.img__wrapper {
  min-height: 200px;
  min-width: 198px;
  position: relative;
  overflow: hidden;
}
.img__wrapper img {
  width: 100%;
  display: none;
}
.sold_out {
  top: 2em;
  left: -4em;
  color: #fff;
  display: block;
  position: absolute;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.06em;
  background-color: #a00;
  padding: 0.5em 5em 0.4em 5em;
  text-shadow: 0 0 0.75em #444;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  font: bold 14px/1.2em Arial, Sans-Serif;
  -webkit-text-shadow: 0 0 0.75em #444;
  -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  -webkit-transform: rotate(-45deg) scale(0.75, 1);
}
.sold_out:before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: -0.3em -5em;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  border: 2px rgba(255, 255, 255, 0.7) dashed;
}
