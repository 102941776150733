.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(25px + 2vw);
  background-color: #f2f2f2;
  padding: 0 calc(10px + 1vw);
}

.navbar-left-section {
  height: 100%;
  display: flex;
}

.navbar-logo {
  min-height: calc(15px + 1vw);
  min-width: calc(15px + 1vw);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.navbar-app-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2px;
}

.navbar-app-name h6 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  line-height: calc(5px + 0.5vw);
}

.navbar-project-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px calc(20px + 2vw);
}

.navbar-project-details h1 {
  margin: 0;
  font-size: calc(8px + 0.5vw);
  line-height: calc(8px + 0.5vw);
}

.navbar-project-details p {
  margin: 0;
  font-size: calc(5px + 0.2vw);
  line-height: calc(5px + 0.2vw);
}

.navbar-project-details h5 {
  margin: calc(5px + 0.3vw) 0 0 0;
  font-size: calc(5px + 0.3vw);
  line-height: calc(5px + 0.3vw);
}
