.products-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.products-filters {
  position: fixed;
  top: calc(100px + 2vw);
  width: 25%;
  height: calc(100% - calc(100px + 2vw));
  border-right: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: left;
  background-color: #ffffff;
}

.airtable-products-filters {
  position: fixed;
  width: 25%;
  height: calc(100% - calc(50px + 2vw));
  border-right: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.products-display {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.products-display-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding: 2px 20px;
  position: relative;
  background-color: #242627;
  color: #ffffff;
}

.products-display-header-left-container {
  /* background-color: red;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center; */
}

.products-display-header-right-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.products-display-header p {
  /* position: absolute; */
  /* right: calc(10px + 1vw); */
  /* bottom: 0; */
  margin: 0 0px;
  font-size: calc(3px + 0.3vw);

  color: #f2f2f2;
  font-weight: bold;
}

.products-display-header h1 {
  margin: 0;
  font-size: calc(8px + 0.5vw);
  text-transform: uppercase;
}

.products-display-header h6 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  font-weight: lighter;
}

.products-display-body {
  padding: calc(5px + 0.5vw);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: scroll;
  flex: 1;
  position: relative;
}

.products-display-airtable {
  /* position: absolute;
  top: 0;
  right: calc(10px + 1vw); */
  cursor: pointer;
  color: #ffffff;
  background-color: #ff4e24;
  border-radius: 100%;
  padding: calc(0.5px + 0.1vw);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
}

.products-display-airtable:hover {
  background-color: #0070da;
}

.products-display-airtable-icon {
  width: calc(10px + 0.5vw);
  height: calc(10px + 0.5vw);
}

.products-display-filter-sort-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  background-color: #ffffff;
  padding: 2px 10px;
}

.products-display-filter-sort-row h6 {
  margin: 0 20px 0 5px;
  font-size: calc(5px + 0.5vw);
}

.products-display-filters {
  display: flex;
  align-items: center;
  width: 75%;
}

.products-display-filters-desc {
  display: flex;
  align-items: center;
  color: #ff4e24;
  cursor: pointer;
}

.products-display-filters-desc:hover {
  color: #0070da;
}

.products-display-filters-desc-icon {
  width: calc(8px + 0.5vw);
  height: calc(8px + 0.5vw);
}

.products-display-filters-icon:hover {
  color: #242627;
}

.products-display-filters-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 5px;
  margin-left: 5px;
}

.products-display-filters-chip p {
  margin: 0;
  font-size: 10px;
  font-weight: bold;
}

.products-display-filters-chip-icon {
  width: 14px;
  height: 14px;
  color: #ea4b5f;
  margin-left: 12px;
  cursor: pointer;
}

.products-display-filters-chip-icon:hover {
  color: #242627;
}

.products-display-sort-by {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
  position: relative;
}

.products-display-sort-by-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ff4e24;
  cursor: pointer;
}

.products-display-sort-by-title:hover {
  color: #0070da;
}

.products-display-sort-by-title h5 {
  font-size: calc(5px + 0.5vw);
  margin: 0 5px 0 0;
  color: #242627;
  font-weight: normal;
}

.products-display-sort-by-icon {
  width: calc(8px + 0.5vw);
  height: calc(8px + 0.5vw);
}

.products-display-sort-by-options {
  position: absolute;
  bottom: 20px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.products-display-sort-by-options-titles {
  border: 1px solid #f2f2f2;
  padding: calc(5px + 0.5vw) 0;
  width: calc(100px + 10vw);
  background-color: #ffffff;
}

.products-display-sort-by-options-titles h3 {
  font-size: calc(5px + 0.5vw);
  margin: 0;
  cursor: pointer;
  padding: calc(5px + 0.5vw) calc(10px + 0.5vw);
}

.products-display-sort-by-options-titles h3:hover {
  background-color: #f2f2f2;
}

.products-display-sort-by-options-more-details {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
}

.products-display-sort-by-option-selected-row {
  display: flex;
  align-items: center;
}

.products-display-sort-by-option-selected-row h5 {
  font-size: calc(5px + 0.5vw);
}

.products-display-sort-by-similar-image-display {
  min-width: 20px;
  min-height: 20px;
  border: 1px solid #dce1eb;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.products-display-close-sort-by-options-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 calc(10px + 0.5vw);
}

.products-display-close-sort-by-options-icon {
  width: 20px;
  height: 20px;
  color: #ff4e24;
  cursor: pointer;
}

.products-display-close-sort-by-options-icon:hover {
  color: #0070da;
}
