.newProduct-card-wrapper {
  padding: calc(5px + 0.5vw) calc(5px + 0.5vw) 0 0;
  position: relative;
}

.newProduct-card-wrapper-container {
  box-shadow: 0px 0px 5px #e5e9f2;
  /* cursor: pointer; */
  background-color: #ffffff;
  border-radius: 5px;
}

.newProduct-card-product-image {
  min-height: 200px;
  min-width: 198px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.newProduct-card-wrapper-container-overlay {
  background-color: rgb(36, 38, 39, 0.8);
  position: absolute;
  top: 0;
  min-height: 200px;
  min-width: 198px;
  max-height: 200px;
  max-width: 198px;
  display: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding: calc(10px + 1vw);
  overflow: scroll;
  color: #ffffff;
  z-index: 1;
}

.overlay-show {
  display: flex;
}

.newProduct-card-wrapper-container-overlay-top h5 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  width: 100%;
  text-align: left;
}

.newProduct-card-wrapper-container-overlay-top h2 {
  margin: calc(2px + 0.2vw) 0;
  font-size: calc(10px + 0.5vw);
  width: 100%;
  text-align: left;
}

.newProduct-card-wrapper-container-overlay-top p {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  width: 100%;
  text-align: left;
}

.newProduct-card-wrapper-container-overlay-bottom {
  width: 100%;
}

.newProduct-card-wrapper-container:hover
  .newProduct-card-wrapper-container-overlay {
  display: flex;
}

.newProduct-qty-wrapper {
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
}

.newProduct-qty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  align-content: center;
  color: #ffffff;
  font-size: 16px;
}
