.statsDropDown-container {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.project-cost {
  border-left: 5px solid #ffffff;

  padding: 0 10px;
  width: 100%;
}
.project-cost div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.project-cost h3 {
  padding: 0;
  margin: 2px;
  font-size: 12px;
}

.highlight {
  background-color: #e0a9a1;
}
