.design-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.design-page-body {
  flex: 1;
  background-color: #f9f9fa;
  padding: calc(20px + 1vw) calc(5px + 0.5vw) calc(5px + 0.5vw)
    calc(5px + 0.5vw);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.design-page-header {
  display: flex;
  align-items: center;
  padding: 5px;
}

.design-page-header h3 {
  font-size: calc(5px + 0.5vw);
  margin: 0 0 0 5px;
  font-weight: lighter;
}

.design-page-header h3 span {
  font-weight: bold;
  cursor: pointer;
  color: #ff4e24;
}

.design-page-header h3 span:hover {
  color: #0070da;
}

.design-page-header-icon {
  width: calc(15px + 1vw);
  height: calc(15px + 1vw);
  cursor: pointer;
  color: #ff4e24;
}

.design-page-header-icon:hover {
  color: #0070da;
}

.design-page-tabs-container {
  width: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.design-page-tab {
  width: calc(100px + 1vw);
  height: calc(10px + 1vw);
  border: 1px solid #ffffff;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.01);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8bfce;
  position: relative;
}

.design-page-tab h5 {
  margin: 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.design-page-tab h5:hover {
  color: #242627;
}

.design-page-tab-close-icon {
  position: absolute;
  right: 2px;
  top: 2px;
  color: #ffffff;
  height: calc(3px + 0.5vw);
  width: calc(3px + 0.5vw);
  cursor: pointer;
}

.design-page-tab-close-icon:hover {
  color: #ff4e24 !important;
}

.design-page-tab:hover .design-page-tab-close-icon {
  color: #b8bfce;
}

.design-page-tab-add-icon-wrapper {
  width: calc(30px + 1vw);
  height: calc(10px + 1vw);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8bfce;
  cursor: pointer;
}

.design-page-tab-add-icon-wrapper:hover .design-page-tab-add-icon {
  color: #242627;
}

.design-page-design-canvas {
  position: relative;
  /* background-color: #f9f9fa; */
  background-color: #3737c9;
  width: 100%;
  height: 100%;
  transform: scale(100vw);
  overflow: hidden;
}

.design-page-design-canvas-zoom-buttons {
  position: absolute;
  top: calc(5px + 0.5vw);
  right: calc(5px + 0.5vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #b8bfce;
}

.design-page-design-canvas-zoom-button {
  width: calc(8px + 0.5vw);
  height: calc(8px + 0.5vw);
  cursor: pointer;
  margin-bottom: calc(4px + 0.4vw);
}

.design-page-design-canvas-zoom-button:hover {
  width: calc(10px + 0.5vw);
  height: calc(10px + 0.5vw);
  color: #ff4e24;
}

.design-page-design-canvas-selectors-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-items: center;
  background-color: #242627;
  border-radius: 100%;
  padding: calc(2px + 0.2vw);
  cursor: pointer;
}

.design-page-design-canvas-selectors-button {
  width: calc(10px + 0.5vw);
  height: calc(10px + 0.5vw);
  color: #ffffff;
}

.design-page-design-canvas-selectors-button-container:hover {
  background-color: #ff4e24;
  border: 1px solid #f2f2f2;
}

.design-page-design-canvas-design-menus-container {
  position: absolute;
  bottom: calc(50px + 2vw);
  left: 0;
}

.design-page-design-canvas-design-items-container {
  position: absolute;
  top: calc(50px + 2vw);
  right: 0;
  bottom: 0;
}

.design-page-products {
  position: relative;
  /* top: calc(50px + 2vw); */
  /* left: 0;
  right: 0; */
  /* top: calc(100px + 2vw); */
  height: 100%;
  /* bottom: calc(10px + 1vw); */
  /* bottom: 0; */
  width: 100%;
  background-color: #ffffff;
}

.design-page-design-canvas-view-boxes {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
  overflow-x: scroll;
  max-width: 100vw;
}

.design-page-products-close-container {
  width: 100%;
  height: 30px;
}

.filters-close-icon {
  width: 30px;
  height: 30px;
  color: #ff4e24;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}

.design-page-design-canvas-view-boxes > :first-child {
  margin-left: auto !important;
}
