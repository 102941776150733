/* .menu {
  position: absolute;
  background: #242627;
  box-shadow: 0px 2px 10px #999999;
  z-index: 1001;
}

.menu--option {
  padding: 6px 50px 5px 10px;
  min-width: 160px;
  cursor: default;
  font-size: 12px;
  color: #ffffff;
}

.menu--option:hover {
  background: linear-gradient(to top, #555, #333);
  color: white;
}

.menu--option:active {
  color: #e9e9e9;
  background: linear-gradient(to top, #555, #444);
}

.menu--separator {
  width: 100%;
  height: 1px;
  background: #cccccc;
  margin: 0 0 0 0;
} */

/* Menu 2 */
/* Menu */

.menu {
  position: absolute;
  width: 200px;
  padding: 2px;
  margin: 0;
  border: 1px solid #bbb;
  background: #eee;
  background: linear-gradient(to bottom, #fff 0%, #e5e5e5 100px, #e5e5e5 100%);
  z-index: 100;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translate(0, 15px) scale(0.95);
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  pointer-events: none;
}

.menu-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.menu-btn {
  display: block;
  color: #444;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 8px;
  border-radius: 3px;
}

button.menu-btn {
  background: none;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 100%;
  text-align: left;
}

a.menu-btn {
  outline: 0 none;
  text-decoration: none;
}

.menu-text {
  margin-left: 25px;
}

.menu-btn .fa {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.menu-item:hover > .menu-btn {
  color: #fff;
  outline: none;
  background-color: #2e3940;
  background: linear-gradient(to bottom, #5d6d79, #2e3940);
  border: 1px solid #2e3940;
}

.menu-item-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.menu-item-disabled .menu-btn {
  cursor: default;
}

.menu-separator {
  display: block;
  margin: 7px 5px;
  height: 1px;
  border-bottom: 1px solid #fff;
  background-color: #aaa;
}

.menu-item-submenu::after {
  content: '';
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-left-color: #808080;
}

.menu-item-submenu:hover::after {
  border-left-color: #fff;
}

.menu .menu {
  top: 4px;
  left: 99%;
}

.menu-show,
.menu-item:hover > .menu {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  pointer-events: auto;
}

.menu-item:hover > .menu {
  transition-delay: 300ms;
}
