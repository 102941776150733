.canvas {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.canvas-wrapper {
  position: relative;
  /* background-color: red; */
  box-shadow: none;
  outline: 0;
  position: relative;
}

.canvas .item {
  position: absolute;
  cursor: pointer;
}

.item .unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.canvas .item .resizer {
  width: 24px;
  height: 24px;

  transform: rotate(135deg);
  cursor: se-resize;
}

.canvas .item .resizer-hidden {
  display: none;
}

.draggable-content {
  position: relative;
  opacity: 1;
  width: 100%;
}

.fh-moodboard--canvas--item-options {
  opacity: 0;
}

.fh-moodboard--canvas--item-options--hidden {
  display: none;
}

.fh-moodboard--canvas--item-quantity {
  opacity: 0;
}

.fh-moodboard--canvas--item-quantity--hidden {
  display: none;
}

.draggable-content:hover .fh-moodboard--canvas--item-quantity {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.5s ease;
  display: flex;
}

.draggable-content:hover .fh-moodboard--canvas--item-options {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: 0.5s ease;
}

.canvas-topBar {
  display: flex;
  justify-content: space-between;
  background-color: rgb(170, 167, 167);
  padding: 5px 5px;
}

.canvas-topBar-historyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.MuiFormControl-root .makeStyles-formControl-9 {
  margin: 0;
  padding: 0;
}

.canvas-topBar-button {
  border-radius: 5px;
  padding: 5px 8px;
  margin-left: 5px;
  font-weight: bold;
}

.canvas-topBar-button-left-container {
  padding: 0;
  height: 100%;
}

.canvas-topBar-button-right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.canvas-topBar-button-right > button {
  border-radius: 5px;
  margin-right: 5px;
}

/* .fh-moodboard--canvas--item__remove {
  flex-grow: 1;
  width: 24px;
  height: 24px;
  margin-top: -24px;
} */

.fh-moodboard--canvas--item__handle {
  align-self: flex-end;
  background-color: red;
}

/* .fh-moodboard--canvas--remove-bg {
  flex-grow: 2;
  width: 24px;
  height: 24px;
  margin-top: -24px;
} */

.canvas-textDrop {
  width: 274px;
  margin: 0;
  padding: 0;
  background-color: #242627;
}

.canvas-textDrop > h1 {
  margin: 0;
  padding: 0;
}

.canvas-textDrop-container {
  position: absolute;
}

.canvas-textDrop-text {
  display: flex;
  cursor: pointer;
}

.canvas-text-edit-button {
  display: none;
}

.apply-font {
  padding: 0;
  margin: 0;
}

.apply-font:hover + .canvas-text-edit-button,
.canvas-text-edit-button:hover {
  display: flex;
  align-items: flex-end;
}

.canvas-textDrop-input {
  border: 0.8px solid black;
  padding: 5px 5px;
  margin: 0;
}

.canvas-textDrop-button {
  padding: 5px 5px;
  margin: 0;
  color: #ffffff;
  border: none;
  border-right: 1px solid #ffffff;
  background-color: #242627;
}

.overlay-image {
  position: relative;
}

/* .overlay-image img {
  animation: slideIn 2s;
}

@keyframes slideIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
} */

/* .draggable-content:hover {
  border: 1px solid black;
  transition: 0.3s ease;
  background-color: rgba(255, 255, 255, 0.5);
} */

.overlay-image .hover {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.overlay-image:hover .hover {
  opacity: 1;
}

.overlay-image .arrow-button {
  color: black;
  font-size: 20px;
  line-height: 1.5em;
  text-shadow: 2px 2px 2px white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* .item:hover {
  z-index: 10000;
} */
