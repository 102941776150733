.projects-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.projects-page-body {
  flex: 1;
  background-color: #f9f9fa;
  padding: 0 calc(20px + 2vw);
}

.projects-page-body h1 {
  font-size: calc(16px + 1vw);
  font-weight: lighter;
}
