.units-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.units-page-body {
  flex: 1;
  background-color: #f9f9fa;
  padding: calc(20px + 2vw);
  display: flex;
  flex-direction: column;
}

.units-page-body-header h1 {
  font-size: calc(16px + 1vw);
  font-weight: lighter;
  margin-bottom: 0;
}

.units-page-body-header p {
  font-size: calc(6px + 0.5vw);
  margin-top: 0;
  width: calc(100px + 30vw);
}

.units-page-body-units {
  position: relative;
  flex: 1;
  width: 100%;
  overflow: scroll;
}

.units-page-body-units-fixed {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.units-page-body-unit-card-container {
  padding: calc(10px + 0.5vw);
}
