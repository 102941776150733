.design-element-card-wrapper {
  padding-right: calc(5px + 0.5vw);
  position: relative;
}
.design-element-card-wrapper-container-overlay-delete {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.design-element-card-wrapper-add:hover {
  background-color: rgb(36, 38, 39, 0.8);
}

.design-element-card-wrapper-container {
  box-shadow: 0px 0px 5px #e5e9f2;
  /* cursor: pointer; */
  background-color: #ffffff;
  border-radius: 5px;
}

.design-element-card-design-element-image {
  min-height: 200px;
  min-width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design-element-card-wrapper-container-overlay {
  background-color: rgb(36, 38, 39, 0.8);
  position: absolute;
  top: 0;
  min-height: 200px;
  min-width: 200px;
  max-height: 200px;
  max-width: 200px;
  display: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: calc(5px + 0.5vw);
  overflow: scroll;
  color: #ffffff;
  z-index: 1;
}

.design-element-card-wrapper-container-overlay-top h5 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  width: 100%;
  text-align: center;
}

.design-element-card-wrapper-container-overlay-top h2 {
  margin: calc(2px + 0.2vw) 0;
  font-size: calc(10px + 0.5vw);
  width: 100%;
  text-align: center;
}

.design-element-card-wrapper-container-overlay-bottom {
  width: 100%;
}

.design-element-card-wrapper-container:hover
  .design-element-card-wrapper-container-overlay {
  display: flex;
}
