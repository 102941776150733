.image-filter-wrapper {
  padding: calc(5px + 0.5vw);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.image-filter-wrapper p {
  font-size: calc(8px + 0.5vw);
  width: 100%;
  text-align: center;
  color: #4d4d4d;
  margin: 5px 0;
}

.image-filter-label {
  color: #4d4d4d;
  margin: 0 0 calc(5px + 0.5vw) 10px;
  display: none;
}

.image-filter-image-container {
  min-width: 200px;
  min-height: 200px;
  border: 1px solid #dce1eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #dce1eb;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-filter-image-container:hover {
  color: #ff4e24;
}

.image-filter-upload-icon {
  width: 50%;
  height: 50%;
}
