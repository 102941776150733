.more-products-card-container {
  min-height: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b8c5d3;
  position: relative;
  cursor: pointer;
}

.more-products-card-container:hover {
  color: #ff4e24;
}

.product-card-more-products-icon {
  width: 50%;
  height: 50%;
}

.product-card-more-products-icon h4 {
  margin: 0;
}
