.documents-view-box-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding: calc(5px + 0.5vw);
  display: flex;
  flex-direction: column;
}

.documents-view-box-title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #b8bfce;
  padding-bottom: calc(3px + 0.2vw);
}

.documents-view-box-title-header h5 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  color: #b8bfce;
}

.documents-view-box-body {
  flex: 1;
  width: 100%;
  overflow: scroll;
}

.documents-view-box-row-item1 {
  width: 3%;
}

.documents-view-box-row-item2 {
  width: 70%;
}

.documents-view-box-row-item3 {
  width: 15%;
}

.documents-view-box-add-button-container {
  position: absolute;
  bottom: 0;
  right: calc(5px + 0.5vw);
  overflow: hidden;
  display: inline-block;
}

.documents-view-box-add-button-container input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.documents-view-box-add-button-container
  input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.documents-view-box-add-button-icon {
  width: calc(15px + 1vw);
  height: calc(15px + 1vw);
}

.documents-view-box-add-button-container:hover
  .documents-view-box-add-button-icon {
  color: #ff4e24;
}
