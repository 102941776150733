.styles-modal-wrapper {
  width: 80vw;
}

.styles-modal-wrapper h4 {
  font-size: calc(12px + 1vw);
  font-weight: lighter;
  margin: 0 0 0 calc(12px + 1vw);
}

.styles-modal-styles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.styles-modal-style-container {
  padding: 20px;
}
