.unit-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 5px #d6dcea;
  border-radius: 5px;
  width: 200px;
  height: 200px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.unit-card-wrapper:hover {
  box-shadow: 0px 5px 20px #d6dcea;
}

.unit-card-description h2 {
  font-size: 14px;
  margin: 0;
}

.unit-card-description p {
  font-size: 12px;
  margin: 0;
}

.unit-card-status {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.unit-card-status p {
  margin: 0 5px 0 0;
  font-size: 10px;
}

.unit-card-status-icon {
  width: 25px;
  height: 25px;
}
