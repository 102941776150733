.search-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-box-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 0 calc(10px + 0.5vw);
}

.search-icon {
  color: #dce1eb;
  width: calc(10px + 1vw);
  height: calc(10px + 1vw);
}
