.page-not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.page-not-found-wrapper h1 {
  color: #f5f6fa;
  font-size: calc(25px + 20vw);
  margin: 0;
  text-align: center;
}

.page-not-found-wrapper h5 {
  margin: 0;
  text-align: center;
}

.page-not-found-wrapper small {
  margin: 0 0 20px 0;
  text-align: center;
}
