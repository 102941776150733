.file-wrapper {
  width: 100%;
}

.file-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding: calc(3px + 0.2vw) 0;
}

.file-details h5 {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  word-wrap: break-word;
}

.file-details-row-item1 {
  width: 3%;
  color: #b8bfce;
}

.file-details-row-item2 {
  width: 70%;
  cursor: pointer;
  color: #0d66d1;
}

.file-details-row-item2:hover {
  color: #ff4e24;
}

.file-details-row-item3 {
  width: 15%;
  color: #b8bfce;
}
/* 
.file-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(25px + 2.5vw);
  padding: 0 calc(2px + 0.2vw);
  border-bottom: 1px solid #f2f2f2;
}

.file-title h5 {
  margin: 0;
  padding: 0;
  font-size: calc(5px + 0.5vw);
  color: #0d66d1;
  font-weight: bold;
  cursor: pointer;
}

.file-title div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
  padding-bottom: calc(2px + 0.2vw);
}

.file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  min-height: 60px;
  overflow-x: auto;
}

.file-loader {
  position: relative;
} */
